import { Injectable, computed, inject, signal } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

export type Language = {
  code: string;
  name: string;
  nativeName: string;
  flag: string;
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  #transloco = inject(TranslocoService);
  #supportedLanguages = signal(this.#transloco.getAvailableLangs() as string[]);
  #supportedLanguageMetadata: Record<string, Language> = {
    en: { code: 'en', name: 'English', nativeName: 'English', flag: 'us' },
    sv: { code: 'sv', name: 'Swedish', nativeName: 'Svenska', flag: 'se' },
    ja: { code: 'ja', name: 'Japanese', nativeName: '日本語', flag: 'jp' },
  };
  #activeLanguage = signal(this.#transloco.getActiveLang());
  activeLanguage = computed(() => this.#supportedLanguageMetadata[this.#activeLanguage()]);
  languages = computed<Language[]>(() =>
    this.#supportedLanguages().map(language => this.#supportedLanguageMetadata[language])
  );

  public setActiveLanguage(language: string) {
    if (this.#supportedLanguages().includes(language)) {
      this.#transloco.setActiveLang(language);
      this.#activeLanguage.set(language);
    }
  }
}
